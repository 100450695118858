const links = [
  {
    header: "ZESTAWY ZAWIESZEŃ 4X4",
    special: true,
    links: [
      {
        to: "/info-mitsubishi-pajero",
        display: "Mitsubishi Pajero",
        specialKits: true,
      },
      // { to: "/", display: "Mitsubishi Shogun" },
      // { to: "/", display: "Nissan Patrol" },
      {
        to: "/info-nissan-navara",
        display: "Nissan Navara",
        specialKits: true,
      },
      {
        to: "/info-nissan-x-trail",
        display: "Nissan X-Trail",
        specialKits: true,
      },
      {
        to: "/info-subaru-forester",
        display: "Subaru Forester",
        specialKits: true,
      },
      // { to: "/", display: "Subaru Outback" },
      // { to: "/", display: "Toyota Hilux 4WD" },
      {
        to: "/info-toyota-landcruiser",
        display: "Toyota Landcruiser",
        specialKits: true,
      },
      // { to: "/", display: "Volkswagen Amarok" },
    ],
  },
  {
    header: "JAK MOŻEMY POMÓC",
    links: [
      { to: "/contact", display: "Kontakt" },
      { to: "/shipping", display: "Opcje dostawy" },
      { to: "/returns", display: "Zwroty" },
      { to: "/warranties", display: "Gwarancja" },
      { to: "/faq", display: "FAQ" },
    ],
  },
  {
    header: "INFORMACJA DODATKOWA",
    links: [
      { to: "/about", display: "O nas" },
      { to: "/kits", display: "Zestawy zawieszeń 4x4" },
      { to: "/privacy", display: "Polityka prywatności" },
      { to: "/terms", display: "Warunki i zobowiązania" },
      { to: "/cookies", display: 'Polityka "Cookies"' },
    ],
  },
  // {
  //   header: "OTHER KITS",
  //   links: [
  //     { to: "/", display: "Chrysler 300C" },
  //     { to: "/", display: "Volkswagen Transporter T5" },
  //   ],
  // },
];

export default links;
