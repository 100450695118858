import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const CheckoutDropdown = ({ children, quantity }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <Wrapper>
      <Dropdown onClick={() => setIsActive((prev) => !prev)}>
        <div>Przedmiotów w koszyku: {quantity || 0}</div>
        <div>
          {isActive ? (
            <FontAwesomeIcon icon={faAngleUp} />
          ) : (
            <FontAwesomeIcon icon={faAngleDown} />
          )}
        </div>
      </Dropdown>
      {isActive ? children : null}
    </Wrapper>
  );
};

export default CheckoutDropdown;

const Wrapper = styled.div`
  margin-bottom: 10px;
`;

const Dropdown = styled.div`
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  display: flex;
  background: #fafafa;
  justify-content: space-between;
  align-items: center;
  color: #666666;
  cursor: pointer;
  border-bottom: 1px solid #d1d1d1;
  padding: 10px 10px 10px 0;
`;
