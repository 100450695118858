import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import SnackbarProvider from "react-simple-snackbar";
import MainLayout from "./components/layouts/MainLayout/MainLayout";
import LayoutContextProvider from "./context/LayoutContextProvider";
import Backdrop from "./components/organisms/Backdrop/Backdrop";
import Drawer from "./components/organisms/Drawer/Drawer";
import ScrollToTop from "./components/organisms/ScrollToTop/ScrollToTop";
import GoUp from "./components/organisms/GoUp/GoUp";
import CookieConsent from "./components/organisms/CookieConsent/CookieConsent";
import AppContextProvider from "./context/AppContext";

const queryClient = new QueryClient();

function App() {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider>
          <ScrollToTop />
          <GoUp />
          <AppContextProvider>
            <LayoutContextProvider>
              <Drawer />
              <CookieConsent />
              <Backdrop />
              <MainLayout />
            </LayoutContextProvider>
          </AppContextProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
