import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { PRIMARY } from "../../../vars/vars";

const PlaceOrderButton = ({ placeOrder, loading }) => (
  <StyledButton
    onClick={placeOrder}
    disabled={loading}
    className="gtm_btn_placeorder"
  >
    {loading ? (
      <FontAwesomeIcon icon={faCog} spin size={"lg"} />
    ) : (
      "Zamów i zapłać"
    )}
  </StyledButton>
);

export default PlaceOrderButton;

const StyledButton = styled.button`
  margin-top: 50px;
  color: white;
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  outline: none;
  border: none;
  background-color: #444;
  height: 35px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 0 20px;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};

  &:hover {
    background-color: ${(props) => (props.disabled ? null : PRIMARY)};
  }
`;
