export const ribbonCategoriesOrder = [
  "SUSPENSION KITS",
  "SPRINGS AND SHOCKS",
  "SUSPENSION PARTS",
  "BUSHES",
  // "DRIVELINE",
  "STEERING",
  "BRAKES",
  "AIRBAGS",
  "OTHER",
];

export const groupDescriptionOrder = [
  { group: "Kits", category: "SUSPENSION KITS", que: "2" },
  { group: "Suspension Kit", category: "SUSPENSION KITS", que: "3" },
  { group: "Suspension Kits", category: "SUSPENSION KITS", que: "4" },
  {
    group: "Coil Over Kits [Full Car]",
    category: "SPRINGS AND SHOCKS",
    que: "5",
  },
  {
    group: "Coil Over Kit [Pair] Multiple Fit",
    category: "SPRINGS AND SHOCKS",
    que: "6",
  },
  { group: "Coilover Kits", category: "SPRINGS AND SHOCKS", que: "7" },
  {
    group: "Complete Strut Assembly (Front Lowered)",
    category: "SPRINGS AND SHOCKS",
    que: "8",
  },
  {
    group: "Complete Strut Assembly (Front Standard)",
    category: "SPRINGS AND SHOCKS",
    que: "9",
  },
  {
    group: "Complete Strut Assembly (Front Raised)",
    category: "SPRINGS AND SHOCKS",
    que: "10",
  },
  {
    group: "Complete Strut Assembly Frt Raised +Load",
    category: "SPRINGS AND SHOCKS",
    que: "11",
  },
  {
    group: "Complete Strut Assembly (Front)",
    category: "SPRINGS AND SHOCKS",
    que: "12",
  },
  { group: "Front Spring Low", category: "SPRINGS AND SHOCKS", que: "13" },
  { group: "Front Spring Standard", category: "SPRINGS AND SHOCKS", que: "14" },
  {
    group: "Front Spring Standard Height",
    category: "SPRINGS AND SHOCKS",
    que: "15",
  },
  { group: "Front Spring Raised", category: "SPRINGS AND SHOCKS", que: "16" },
  { group: "Front Shocks", category: "SPRINGS AND SHOCKS", que: "17" },
  { group: "Coils", category: "SPRINGS AND SHOCKS", que: "18" },
  { group: "Front Shock Mounts", category: "SUSPENSION PARTS", que: "19" },
  { group: "Front Spring Insulator", category: "SUSPENSION PARTS", que: "20" },
  { group: "Front U Bolts", category: "SUSPENSION PARTS", que: "21" },
  {
    group: "Front Alignment Products",
    category: "SUSPENSION PARTS",
    que: "22",
  },
  {
    group: "Chassis Geometry Correction Kits",
    category: "SUSPENSION PARTS",
    que: "23",
  },
  {
    group: "Complete Strut Assembly (Rear)",
    category: "SPRINGS AND SHOCKS",
    que: "24",
  },
  {
    group: "Complete Strut Assembly (Rear Raised)",
    category: "SPRINGS AND SHOCKS",
    que: "25",
  },

  {
    group: "Rear Spring Low",
    category: "SPRINGS AND SHOCKS",
    que: "26",
  },
  {
    group: "Rea spring standard height",
    category: "SPRINGS AND SHOCKS",
    que: "27",
  },
  { group: "Rear Spring Standard", category: "SPRINGS AND SHOCKS", que: "28" },
  { group: "Rear Spring Standard", category: "SPRINGS AND SHOCKS", que: "29" },
  {
    group: "Rear Spring Standard Height",
    category: "SPRINGS AND SHOCKS",
    que: "30",
  },
  {
    group: "Rear Spring Raised (Variable Leaf pack)",
    category: "SPRINGS AND SHOCKS",
    que: "31",
  },
  { group: "Rear Spring Raised", category: "SPRINGS AND SHOCKS", que: "32" },
  { group: "Rear Shock", category: "SPRINGS AND SHOCKS", que: "33" },
  { group: "Rear Shocks", category: "SPRINGS AND SHOCKS", que: "34" },
  { group: "Rear Shock Mounts", category: "SPRINGS AND SHOCKS", que: "35" },
  { group: "Rear Spring Insulator", category: "SPRINGS AND SHOCKS", que: "36" },
  { group: "Spare parts (shocks)", category: "SPRINGS AND SHOCKS", que: "37" },
  { group: "Spring Seat", category: "SPRINGS AND SHOCKS", que: "38" },
  { group: "Leaf Spring Shackles", category: "SUSPENSION PARTS", que: "39" },
  { group: "Shackles", category: "SUSPENSION PARTS", que: "40" },
  { group: "Rear Spring All Bushes / Kits", category: "BUSHES", que: "41" },
  { group: "Upper Inner Bush", category: "BUSHES", que: "42" },
  { group: "Rear Shackle Upper Bushes", category: "BUSHES", que: "43" },
  { group: "Rear Shackle Lower Bushes", category: "BUSHES", que: "44" },
  { group: "Rear Shackle Bushes / Kits", category: "BUSHES", que: "45" },
  { group: "Rear Shackle Lower Bushes / Kits", category: "BUSHES", que: "46" },
  { group: "Rear Fixed Eye Bushes / Kits", category: "BUSHES", que: "47" },
  { group: "Rear Crossmember Bushes", category: "BUSHES", que: "48" },
  { group: "Rear Anti-roll Bar Bushes", category: "BUSHES", que: "49" },
  { group: "R Of R Shackle Bushes / Kit", category: "BUSHES", que: "50" },
  { group: "R of F Shackle Bushes / Kit", category: "BUSHES", que: "51" },
  { group: "Multi Use Shock Bushes", category: "BUSHES", que: "52" },
  { group: "Front Spring Bushes / Kits", category: "BUSHES", que: "53" },
  { group: "Front Shackle Bushes / Kits", category: "BUSHES", que: "54" },
  { group: "Front Shackle Lower Bushes", category: "BUSHES", que: "55" },
  { group: "Front Shackle Upper Bushes", category: "BUSHES", que: "56" },
  { group: "Front Fixed Eye Bushes / Kits", category: "BUSHES", que: "57" },
  { group: "F Of R Shackle Bushes / Kit", category: "BUSHES", que: "58" },
  { group: "F of F Shackle Bushes / Kit", category: "BUSHES", que: "59" },
  {
    group: "Front Leading / Trailing Arm Bushes",
    category: "BUSHES",
    que: "60",
  },
  { group: "Idler Arm &/or Bush Kit", category: "BUSHES", que: "61" },

  {
    group: "Rear Panhard Rod / Watts Link Bushes",
    category: "BUSHES",
    que: "62",
  },
  { group: "Bump Stop", category: "SUSPENSION PARTS", que: "63" },
  { group: "Dust Boot", category: "SUSPENSION PARTS", que: "64" },
  { group: "Adjusters", category: "SUSPENSION PARTS", que: "65" },
  { group: "Clevis Bracket", category: "SUSPENSION PARTS", que: "66" },
  {
    group: "Extended Brake Hose Sets",
    category: "SUSPENSION PARTS",
    que: "67",
  },
  { group: "Engine Mounts", category: "SUSPENSION PARTS", que: "68" },
  { group: "Rear U Bolts", category: "SUSPENSION PARTS", que: "69" },
  { group: "Rear U-Bolts", category: "SUSPENSION PARTS", que: "70" },
  { group: "Rear Alignment Products", category: "SUSPENSION PARTS", que: "71" },
  { group: "Rear Anti-roll Bar", category: "SUSPENSION PARTS", que: "72" },
  {
    group: "Rear Anti-roll Bar Links",
    category: "SUSPENSION PARTS",
    que: "73",
  },
  { group: "Rear Anti-roll Bars", category: "SUSPENSION PARTS", que: "74" },
  { group: "Rear Ball Joints", category: "SUSPENSION PARTS", que: "75" },
  { group: "Rear Bump Stop / Kit", category: "SUSPENSION PARTS", que: "76" },
  {
    group: "Rear Chassis/Body mounts",
    category: "SUSPENSION PARTS",
    que: "77",
  },
  { group: "Rear Hub", category: "SUSPENSION PARTS", que: "78" },
  { group: "Rear Lateral Arm Lower", category: "SUSPENSION PARTS", que: "79" },
  { group: "Rear Lateral Arm Upper", category: "SUSPENSION PARTS", que: "80" },
  { group: "Rear Miscellaneous", category: "SUSPENSION PARTS", que: "81" },
  { group: "Rear Toe Arm / Link", category: "SUSPENSION PARTS", que: "82" },
  { group: "Rear Trailing Arm Lower", category: "SUSPENSION PARTS", que: "83" },
  { group: "Rear Trailing Arm Upper", category: "SUSPENSION PARTS", que: "84" },
  {
    group: "Rear Undercar Protection",
    category: "SUSPENSION PARTS",
    que: "85",
  },
  { group: "Rear Wheel Cylinder", category: "SUSPENSION PARTS", que: "86" },
  { group: "Relay Rod", category: "SUSPENSION PARTS", que: "87" },

  {
    group: "Relay Rod End (Front)",
    category: "SUSPENSION PARTS",
    que: "88",
  },
  {
    group: "Relay Rod End (Rear)",
    category: "SUSPENSION PARTS",
    que: "89",
  },
  {
    group: "Tie Rod End Set",
    category: "SUSPENSION PARTS",
    que: "90",
  },
  {
    group: "Upper Ball Joint",
    category: "SUSPENSION PARTS",
    que: "91",
  },
  {
    group: "CV Shaft Assembly (Front)",
    category: "STEERING",
    que: "92",
  },
  {
    group: "CV Shaft Assembly (Rear)",
    category: "STEERING",
    que: "93",
  },
  { group: "Drag Link", category: "STEERING", que: "94" },
  { group: "Inner Cv Boot (Front)", category: "STEERING", que: "95" },
  { group: "Inner Cv Boot (Rear)", category: "STEERING", que: "96" },
  { group: "Manual Steering Gear", category: "STEERING", que: "97" },
  { group: "Outer Cv Boot (Front)", category: "STEERING", que: "98" },
  { group: "Outer Cv Boot (Rear)", category: "STEERING", que: "99" },
  { group: "Power Steering Gear", category: "STEERING", que: "100" },
  { group: "Power Steering Hose", category: "STEERING", que: "101" },
  { group: "Power Steering Pump", category: "STEERING", que: "102" },
  { group: "Steering Dampers", category: "STEERING", que: "103" },
  {
    group: "Steering Gear Couplings and  Mounts",
    category: "STEERING",
    que: "104",
  },
  { group: "Air Tubing", category: "AIRBAGS", que: "105" },
  { group: "Airbag Controller Kits", category: "AIRBAGS", que: "106" },
  { group: "Air Bag Controllers", category: "AIRBAGS", que: "107" },
  { group: "Front Airbag", category: "AIRBAGS", que: "108" },
  { group: "High Pressure Sleeve Kits", category: "AIRBAGS", que: "109" },
  { group: "Inflation Valve", category: "AIRBAGS", que: "110" },
  { group: "Load Assist Device", category: "AIRBAGS", que: "111" },
  { group: "Rear Air Bag", category: "AIRBAGS", que: "112" },
  { group: "Rear Airbag", category: "AIRBAGS", que: "113" },
  { group: "Warranty Replacement Airbag", category: "AIRBAGS", que: "114" },
  { group: "Brake Upgrade Kits", category: "BRAKES", que: "115" },
  { group: "Drum to Disc Conversion Kits", category: "BRAKES", que: "116" },
  { group: "Brake Upgrade Kit Spares", category: "BRAKES", que: "117" },
  { group: "Brake Upgrade Kit Components", category: "BRAKES", que: "118" },
  { group: "Brake Servo", category: "BRAKES", que: "119" },
  { group: "Front Brake Shoes", category: "BRAKES", que: "120" },
  { group: "Brake Pads", category: "BRAKES", que: "121" },
  { group: "Front Brake Pads", category: "BRAKES", que: "122" },
  { group: "Brake Discs", category: "BRAKES", que: "123" },

  {
    group: "Front Brake Rotors",
    category: "BRAKES",
    que: "124",
  },
  { group: "Front Brake Discs", category: "BRAKES", que: "125" },
  { group: "Front Brake Pad Sensor", category: "BRAKES", que: "126" },
  {
    group: "Front Brake Upgrade Kit Components",
    category: "BRAKES",
    que: "127",
  },
  { group: "Front Calipers", category: "BRAKES", que: "128" },
  { group: "Front Wheel Cylinder", category: "BRAKES", que: "129" },
  { group: "Park Brake Assembly", category: "BRAKES", que: "130" },
  {
    group: "Pedders Drum to Disc Conversion Spares",
    category: "BRAKES",
    que: "131",
  },
  { group: "Drum to Disc Conversion Spares", category: "BRAKES", que: "132" },
  {
    group: "Radius / Brake Reaction Rod or Bushes",
    category: "BRAKES",
    que: "133",
  },
  { group: "Rear Brake Drums", category: "BRAKES", que: "134" },
  { group: "Rear Brake Hoses", category: "BRAKES", que: "135" },
  { group: "Front Brake Hoses", category: "BRAKES", que: "136" },
  { group: "Rear Brake Pad Sensor", category: "BRAKES", que: "137" },
  { group: "Rear Brake Pads", category: "BRAKES", que: "138" },
  { group: "Rear Brake Rotors", category: "BRAKES", que: "139" },
  { group: "Rear Brake Shoes", category: "BRAKES", que: "140" },
  { group: "Rear Calipers", category: "BRAKES", que: "141" },
  { group: "Brake Fluid", category: "BRAKES", que: "142" },

  {
    group: "Front Anti-roll Bar",
    category: "SUSPENSION PARTS",
    que: "143",
  },
  {
    group: "Front Anti-roll Bar Bushes",
    category: "SUSPENSION PARTS",
    que: "144",
  },
  {
    group: "Front Anti-roll Bar Links",
    category: "SUSPENSION PARTS",
    que: "145",
  },
  { group: "Front Anti-roll Bars", category: "SUSPENSION PARTS", que: "146" },
  { group: "Front Bump Stop/Kit", category: "SUSPENSION PARTS", que: "147" },
  {
    group: "Front Chassis/Body mounts",
    category: "SUSPENSION PARTS",
    que: "148",
  },
  { group: "Control Arms", category: "SUSPENSION PARTS", que: "149" },
  { group: "Front Hub", category: "SUSPENSION PARTS", que: "150" },
  {
    group: "Front Lower Control Arm",
    category: "SUSPENSION PARTS",
    que: "151",
  },
  { group: "Front Miscellaneous", category: "SUSPENSION PARTS", que: "152" },
  {
    group: "Front Panhard Rod / Watts Link Bushes",
    category: "SUSPENSION PARTS",
    que: "153",
  },
  {
    group: "Front Undercar Protection",
    category: "SUSPENSION PARTS",
    que: "154",
  },
  {
    group: "Front Upper Control Arm",
    category: "SUSPENSION PARTS",
    que: "155",
  },
  { group: "Gas Lifter - Boot", category: "SUSPENSION PARTS", que: "156" },
  { group: "Inner Tie Rod End", category: "SUSPENSION PARTS", que: "157" },
  {
    group: "LoadRyder Onboard Scales",
    category: "SUSPENSION PARTS",
    que: "158",
  },
  { group: "Lower Ball Joint", category: "SUSPENSION PARTS", que: "159" },
  { group: "Lower Inner Bush", category: "SUSPENSION PARTS", que: "160" },
  { group: "Lowering Blocks", category: "SUSPENSION PARTS", que: "161" },
  { group: "Master Cylinder", category: "SUSPENSION PARTS", que: "162" },
  {
    group: "Multi Use Shock Fitting Kits",
    category: "SUSPENSION PARTS",
    que: "163",
  },
  { group: "Other", category: "SUSPENSION PARTS", que: "164" },
  { group: "Outer Tie Rod End", category: "SUSPENSION PARTS", que: "165" },
  { group: "Pitman Arm", category: "SUSPENSION PARTS", que: "166" },
];
