export const getErrorText = (error) =>
  error.response ? error.response.data.message : error.message;

export const loadScript = (url, callback) => {
  const script = document.createElement("script");
  script.src = url;
  document.body.appendChild(script);
  script.onload = () => {
    if (callback) callback();
  };
};
