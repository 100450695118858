import React, { useContext } from "react";
import styled from "styled-components";
import layoutContext from "../../../context/LayoutContext";
import { useAppContext } from "./../../../context/AppContext";

const Backdrop = () => {
  const { initialAction } = useAppContext();
  const context = useContext(layoutContext);

  if (!initialAction) return null;
  if (context.showDrawer || context.showCookie) {
    document.body.style.overflow = "hidden";
    document.getElementById("root").style.overflow = "hidden";
    return <StyledBackdrop onClick={context.toggleDrawer} />;
  } else {
    document.body.style.overflow = "visible";
    document.getElementById("root").style.overflow = "visible";
    return null;
  }
};

export default Backdrop;

const StyledBackdrop = styled.div`
  background-color: #0000004d;
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
