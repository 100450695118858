import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PRIMARY } from "../../../vars/vars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

const GoUp = () => {
  const [visible, setVisible] = useState(false);

  const scroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const setVisibility = () => {
      const value = window.scrollY > 500;
      setVisible(value);
    };

    window.addEventListener("scroll", setVisibility);
    return () => window.removeEventListener("scroll", setVisibility);
  }, []);

  return (
    <StyledGoUp onClick={scroll} visible={visible}>
      <FontAwesomeIcon icon={faArrowUp} size={"lg"} />
    </StyledGoUp>
  );
};

export default GoUp;

const StyledGoUp = styled.div`
  z-index: 1000;
  position: fixed;
  bottom: 30px;
  right: 30px;
  padding: 6px 8px;
  background-color: ${PRIMARY};
  border-radius: 50%;
  color: white;
  cursor: pointer;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.2s;
  
  @media (max-width: 550px) {
    position: fixed;
    right: 20%;
  }
  @media (max-width: 460px) {
    position: fixed;
    right: 150px;
  }
  @media (max-width: 425px) {
    position: fixed;
    right: 35%;
  }
  @media (max-width: 375px) {
    display: none;
  }
`;
