import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { faShoppingBasket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PRIMARY } from "../../../vars/vars";
import MiniBasket from "../../organisms/MiniBasket/MiniBasket";
import { useAppContext } from "../../../context/AppContext";

const SmallShoppingCart = () => {
  const [showMiniBasket, setShowMiniBasket] = useState(false);
  const { basketCount, summary } = useAppContext();

  const basketText = () => `Produktów: ${basketCount || 0}`;

  const handleClick = () => {
    setShowMiniBasket((prev) => !prev);
  };

  const myRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (myRef.current && !myRef.current.contains(event.target)) {
        setShowMiniBasket(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [myRef]);

  return (
    <>
      <StyledSmallShoppingCart>
        <Icon onClick={handleClick} aria-label="Przycisk pokaż koszyk">
          <FontAwesomeIcon icon={faShoppingBasket} color={"white"} />
        </Icon>
        <Content>
          <Header>Koszyk</Header>
          <Basket>
            {basketText()} - {(summary && summary.grossitemsamount) || "0.00"}{" "}
            ZŁ
          </Basket>
        </Content>
        <div ref={myRef}>
          <MiniBasket
            showMiniBasket={showMiniBasket}
            setShowMiniBasket={setShowMiniBasket}
          />
        </div>
      </StyledSmallShoppingCart>
    </>
  );
};

export default SmallShoppingCart;

const StyledSmallShoppingCart = styled.div`
  display: flex;
  position: relative;
`;

const Header = styled.div`
  color: #333333;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
`;

const Basket = styled.div`
  color: ${PRIMARY};
  font-size: 12px;
  font-weight: 700;
`;

const Content = styled.div`
  display: none;

  @media screen and (min-width: 992px) {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
`;

const Icon = styled.button`
  border-radius: 25px;
  background-color: ${PRIMARY};
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 100;
  border: none;
  margin-left: 15px;

  &:focus {
    outline: none;
  }

  @media screen and (min-width: 992px) {
    margin: 0 15px;
  }
  @media (max-width: 425px) {
    margin: 0 10px 0 0;
  }
`;
