import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import UnderlinedHeader from "../../molecules/UnderlinedHeader/UnderlinedHeader";
import BasketCart from "../../organisms/BasketCart/BasketCart";
import BasketSummary from "../../organisms/BasketSummary/BasketSummary";
import useBasketActions from "../../../hooks/useBasketActions";
import CountryAlert from "../../organisms/CountryAlert/CountryAlert";
import { useAppContext } from "../../../context/AppContext";

const Basket = () => {
  const { basket } = useAppContext();
  const {
    updateHandler,
    deleteHandler,
    isLoading,
    bulkUpdateHandler,
    updateCountryWithTax,
  } = useBasketActions();
  const [country, setCountry] = useState((basket && basket.shipcountry) || "");
  const [delivery, setDelivery] = useState("");

  const deliveryHandler = async (e) => {
    const value = e.target.value;
    if (country) {
      const changes = [
        {
          field: "shippingmethod",
          value,
        },
      ];
      bulkUpdateHandler(changes);
    }
    setDelivery(value);
  };

  const countryHandler = (e) => {
    const value = e.target.value;
    updateCountryWithTax(value);
    setCountry(value);
  };

  return (
    <BasketWrapper>
      <Container>
        <UnderlinedHeader text={"Koszyk"} bolder />
        <CountryAlert full />
        <Content>
          <BasketCart
            isLoading={isLoading}
            deleteHandler={deleteHandler}
            updateHandler={updateHandler}
          />
          <BasketSummary
            country={country}
            countryHandler={countryHandler}
            isLoading={isLoading}
            radioValue={delivery}
            radioChangeHandler={deliveryHandler}
          />
        </Content>
      </Container>
    </BasketWrapper>
  );
};

export default Basket;

const BasketWrapper = styled.div`
  padding-top: 92px;
  padding-bottom: 30px;
`;
const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    "basket"
    "summary";
  column-gap: 20px;

  @media screen and (min-width: 1000px) {
    grid-template-columns: 2fr 1fr;
    grid-template-areas: "basket summary";
  }
`;
