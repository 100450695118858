import React, { useState } from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

const SearchArea = () => {
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const history = useHistory();
  const clickHandler = () => {
    history.push("/search/" + search);
  };
  const keyDownHandler = (event) => {
    if (event.key === "Enter") {
      history.push("/search/" + search);
    }
  };

  return (
    <Wrapper
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <StyledIcon
        disabled={!search.length}
        onClick={clickHandler}
        aria-label="Przycisk wyszukiwania"
      >
        <FontAwesomeIcon icon={faSearch} color={"#666666"} />
      </StyledIcon>
      {show && (
        <Input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          type={"text"}
          name={"search"}
          placeholder={"Szukaj..."}
          maxLength={"128"}
          onKeyDown={keyDownHandler}
        />
      )}
    </Wrapper>
  );
};

export default SearchArea;

const Input = styled.input`
  margin-left: 40px;
  border: 1px solid #adadad;
  line-height: 1.66;
  background-color: #fff;
  width: 230px;
  height: 42px;
  padding: 0 40px 0 15px;
  border-radius: 21px;
  font-size: 12px;
  position: absolute;
  z-index: 50;
  right: 0;

  &::placeholder {
    font-size: 12px;
  }

  &:focus {
    outline: none;
  }
  @media (max-width: 425px) {
    width: 200px;
    margin-left: 0;
    right: 10px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  @media (max-width: 991px) {
    right: 50%;
  }
  @media (max-width: 767px) {
    right: 0;
  }
`;

const StyledIcon = styled.button`
  border-radius: 25px;
  background-color: rgb(235, 235, 237);
  width: 42px;
  margin-left: 40px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  z-index: 100;
  border: none;
  right: 0;
  z-index: 100;

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  @media (max-width: 425px) {
    margin-right: 10px;
  }
`;
