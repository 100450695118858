import React, { useContext } from "react";
import styled from "styled-components";
import layoutContext from "../../../context/LayoutContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

const Hamburger = () => {
  const { toggleDrawer } = useContext(layoutContext);

  return (
    <StyledHamburger
      onClick={toggleDrawer}
      aria-label="Przycisk otwierania menu"
    >
      <FontAwesomeIcon icon={faBars} />
    </StyledHamburger>
  );
};

export default Hamburger;

const StyledHamburger = styled.button`
  width: 42px;
  height: 42px;
  font-size: 24px;
  line-height: 42px;
  background-color: #ebebed;
  border-radius: 50%;
  color: #333;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:active,
  &:focus {
    outline: none;
  }
`;
