import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare } from "@fortawesome/free-solid-svg-icons";
import { deliveryTime } from "../../../vars/vars";

const InStock = () => (
  <>
    <Header>
      <FontAwesomeIcon icon={faCheckSquare} color={"#50be19"} />
      <StyledStock>Na stanie</StyledStock>
    </Header>
    <StyledAvl>{deliveryTime.IN_STOCK}</StyledAvl>
  </>
);

export default InStock;

const Header = styled.div`
  display: flex;
  font-size: 22px;
  align-items: center;
`;

const StyledStock = styled.div`
  color: #50be19;
  margin-left: 5px;
  font-weight: 700;
  font-family: "Open Sans", sans-serif;
`;
const StyledAvl = styled.div`
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: rgb(102, 102, 102);
`;
