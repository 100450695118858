import { useContext, useEffect, useState } from "react";
import {
  getApplicationsItems,
  getItemData,
  getRelatedItems,
} from "../axios/api";
import { getErrorText } from "../vars/functions";
import layoutContext from "../context/LayoutContext";
import { useAppContext } from "../context/AppContext";

const useItemDetails = () => {
  const [record, setRecord] = useState("");
  const [error, setError] = useState("");
  const [part, setPart] = useState("");
  const [isActive, setIsActive] = useState(false);

  const context = useContext(layoutContext);
  const { userCountry } = useAppContext();

  const getData = async () => {
    setIsActive(true);
    context.setSmallNavText("");

    if (record && userCountry) {
      try {
        const [{ data: dataFrom3c }] = await getItemData(
          record,
          userCountry.code
        );
        if (isActive) {
          const { description, name } = dataFrom3c;
          context.setSmallNavText(`${description} ${name}`);
          const relatedItems = await getRelatedItems(dataFrom3c.id);
          const applications = await getApplicationsItems(dataFrom3c.id);
          const product = {
            ...dataFrom3c,
            relateditems: [...dataFrom3c.relateditems, ...relatedItems.data],
            applications: applications.data.results,
          };
          setPart(product);
        }
      } catch (e) {
        setError(getErrorText(e));
      }
    }
  };

  useEffect(() => {
    if (!part) {
      getData();
      return () => {
        setIsActive(false);
      };
    }
  }, [record, userCountry]);

  return [{ part, error }, setRecord, setError];
};

export default useItemDetails;
