import React from "react";
import styled from "styled-components";
import UnderlinedHeader from "../UnderlinedHeader/UnderlinedHeader";
import FooterLink from "../../atoms/FooterLink/FooterLink";
import { BREAKPOINT } from "../../../vars/vars";

const FooterGroup = ({ header, links, special }) => (
  <StyledFooterGroup special={special}>
    <UnderlinedHeader text={header} />
    {links.map(({ to, display, direct, specialKits }) => (
      <FooterLink
        key={display}
        to={to}
        display={display}
        direct={direct}
        specialKits={specialKits}
      />
    ))}
  </StyledFooterGroup>
);

export default FooterGroup;

const StyledFooterGroup = styled.div`
  padding: 20px 0;
  background-color: ${(props) => (props.special ? "#f9f9f9" : null)};

  @media screen and (min-width: ${BREAKPOINT}) {
    grid-column: ${(props) => (props.special ? "1 / 4" : null)};
    padding: 70px 30px 60px;
  }

  @media screen and (min-width: 992px) {
    grid-column: auto;
  }
`;
