import React from "react";
import styled from "styled-components";
import ProgressItem from "../../atoms/ProgressItem/ProgressItem";

const OrderProgress = () => (
  <Progress>
    <ProgressItem label={"Dostawa"} step={"1"} />
    <ProgressItem label={"Podsumowanie i zamówienie"} step={"2"} />
    <ProgressItem label={"Płatność i potwierdzenie"} step={"3"} />
  </Progress>
);

export default OrderProgress;

const Progress = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;
