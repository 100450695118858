import React from "react";
import FsLightbox from "fslightbox-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export const LightBox = ({ images, toggler }) => {

  return (
    <>
      <button
        style={{
          position: "absolute",
          top: "0px",
          right: "0px",
          border: "none",
          backgroundColor: "transparent",
          pointerEvents: "none",
        }}
      >
        <FontAwesomeIcon icon={faSearch} color={"#666666"} />
      </button>
      <FsLightbox
        toggler={toggler}
        sources={images.map((obj) => obj.url)}
        type="image"
        style={{ border: "3px solid red" }}
      />
    </>
  );
};
