import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { PRIMARY } from "../../../vars/vars";

const OutOfStock = () => (
  <>
    <Header>
      <FontAwesomeIcon icon={faTimes} color={PRIMARY} />
      <StyledStock>Brak na stanie</StyledStock>
    </Header>
  </>
);

export default OutOfStock;

const Header = styled.div`
  display: flex;
  font-size: 20px;
  align-items: center;
`;

const StyledStock = styled.span`
  color: ${PRIMARY};
  margin-left: 5px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
`;
