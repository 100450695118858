import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SummaryHeader = ({ text, cog, active }) => {
  const history = useHistory();

  return (
    <StyledComponent>
      <div>{text}</div>
      {cog && active && (
        <StyledIcon
          icon={faCog}
          onClick={() => history.push(cog)}
          className="gtm_btn_checkout"
        />
      )}
    </StyledComponent>
  );
};

export default SummaryHeader;

const StyledComponent = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  color: #333333;
  padding-bottom: 10px;
  border-bottom: 1px solid #d1d1d1;
  align-items: center;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: #8f8f8f;
  &:hover {
    cursor: pointer;
    color: black;
  }
`;
