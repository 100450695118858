import React from "react";
import { Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const DetailsAlert = ({ part, paid, other }) => (
  <Alert variant={"success"}>
    <FontAwesomeIcon icon={faCheck} color={"#006400"} />
    {paid ? (
      <StyledSpan>Zamówienie zostało opłacone.</StyledSpan>
    ) : part ? (
      <StyledSpan>
        Dodano do koszyka: {part.description} {part.name}.
      </StyledSpan>
    ) : (
      <StyledSpan>{other}</StyledSpan>
    )}
  </Alert>
);

export default DetailsAlert;

const StyledSpan = styled.span`
  display: inline-block;
  margin-left: 10px;
`;
