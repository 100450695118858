import { faHeart, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default [
  {
    path: "/about",
    text: "O nas",
    icon: <FontAwesomeIcon icon={faHeart} />,
  },
  {
    path: "https://www.facebook.com/PeddersPolska",
    text: "PeddersPolska",
    direct: true,
    icon: <FontAwesomeIcon icon={faFacebook} />,
  },
  {
    path: "/contact",
    text: "Kontakt",
    icon: <FontAwesomeIcon icon={faPhoneAlt} />,
  },
];
