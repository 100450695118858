import React from "react";

const layout = {
  showDrawer: false,
  toggleDrawer: () => {},
  showCookie: true,
  setShowCookie: () => {},
  smallNavText: "",
  setSmallNavText: () => {},
  translations: {},
  catalogueHeader: "",
  setCatalogueHeader: () => {},
  loader: false,
  setLoader: () => {},
};

const layoutContext = React.createContext(layout);

export default layoutContext;
